import React, {useState} from 'react';
import flores from '../../assets/vid.mp4';
import music from '../../assets/music.mp3';
import pic01 from '../../assets/pic01.jpg';
import pic03 from '../../assets/pic03.jpg';
import pic04 from '../../assets/pic04.jpg';
import ReactAudioPlayer from "react-audio-player";
function VideoBackground() {
    const [aberto, setAberto] = useState(false);

    const handleClick = () => {
        setAberto(!aberto);
    };

    return <>
        <video
            autoPlay
            loop
            muted
            playsInline
            className="fixed inset-0 object-cover h-screen w-screen cursor-pointer"
            onClick={handleClick}
        >
            <source src={flores} type="video/mp4"/>
            Seu navegador não suporta este formato de vídeo.
        </video>


        <div className="absolute z-10 text-white font-bold text-5xl	text-center w-full mt-[50px]">
            <p> Clique na Tela!</p>
        </div>


        {aberto &&
            <div
                className={`m-20 ml-20 fixed inset-0 bg-letter bg-opacity-80 overflow-auto`}
            >
                <ReactAudioPlayer
                    autoPlay
                    loop
                    playsInline
                    controls
                    className='absolute hidden'
                    volume={0.3}
                    src={music}
                />
                <div className="absolute w-full h-full rounded-lg p-10 font-lucida-handwriting">
                    <div className='text-center'>
                        <h2 className="text-xl font-bold">Uma carta para Isadora Espíndola</h2>
                        <h4 className="font-bold">Feliz aniversario amor</h4>
                    </div>

                    <div className='text-right'>
                        <p className="text-sm">06 de Novembro de 2014</p>
                    </div>

                    <div className='p-[20px] indent-8 text-xl'>
                        <p> Hoje é seu aniversário e eu queria escrever algo para você, mas é tão difícil me expressar em palavras, tudo que temos vivido nesses meses juntos, o quão feliz você me faz, no dia de hoje, peço a Deus para abençoar a sua vida, iluminar seus caminhos, todos os teus planos, que Nossa Senhora possa passar à frente da sua vida e interceder por cada aspecto. Você é uma pessoa incrível, a melhor namorada do mundo, minha companheira, o amor da minha vida. .</p>
                        <p> Sabe aquela sensação dos 15 anos? De ficar nervoso para ir te ver? Ainda sinto, como se fosse a primeira vez que a gente saiu, você me faz tão bem, faz eu me sentir leve, quando eu estou com você, meu mundo fica em silencio, tudo fica em paz, me sinto em casa quando estou com você, seja em qualquer lugar. </p>
                        <p> Quero usar dessa carta para dizer a você, o quão especial você é, como você é dedicada, estudiosa, carinhosa, companheira, eu não tenho nenhuma dúvida do sucesso que você é e o tanto que ainda tem um futuro lindo pela frente, você já conquistou muita coisa e ainda tem muito mais para conquistar, seu futuro é lindo e peço a Deus todos os dias que me permita acompanhar todo o seu sucesso e crescer junto com você. </p>
                        <p> Você é muito mais do que pedi pra Deus, muito mais do que eu jamais sonhei, hoje 140 dias depois de me aceitar como seu namorado, vejo o quanto Deus foi bom comigo, o quanto ele me honrou, o quanto Ele sabia de tudo e orquestrou para que nos encontrássemos na hora certa, aqui e agora. </p>
                        <p> Eu amo você com todo meu coração, você é o amor da minha vida, muitas felicidades, que todos os seus sonhos se realizem, que você possa conquistar tudo que almeja, estou do seu lado, para lutar contra o mundo se for preciso, até o céu! </p>
                    </div>
                    <div className='flex flex-row justify-center space-x-8'>

                        <div className='rounded-lg w-[350px] h-full bg-white p-1'>
                            <img src={pic01} alt='pic01'/>
                        </div>
                        <div className='rounded-lg w-[350px] h-full bg-white p-1'>
                            <img src={pic04} alt='pic02'/>
                        </div>
                        <div className='rounded-lg w-[350px] h-full bg-white p-1'>
                            <img src={pic03}  alt='pic03'/>
                        </div>

                    </div>
                    <div className="p-[10px] text-right">
                        <p>&copy; 2024 Guilherme Ramos</p>
                    </div>
                </div>
            </div>
        }
    </>;
}

export default VideoBackground;