import './App.css';
import React from "react";
import VideoBackground from "./pages/components/video";

function App() {
  return (

      <div className={` h-screen `}>
          <VideoBackground />

      </div>
)
  ;
}

export default App;
